@import "../node_modules/nes.css/css/nes.css";

.App {
  text-align: center;
  background-image: url('https://i.pinimg.com/originals/e0/7e/a2/e07ea2eda9d787e4de5bfce55fd70f2c.jpg');
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App::before{
  content:' ';
  min-height:100vh;
  width:100vw;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(0,0,0,.5) 0%, rgba(0,0,0,.9) 80%);
  position: absolute;
  z-index:2;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index:3;
}

.eth-token-container {
  margin-bottom: 20px;
}

.tagline-container {
  margin-bottom: 20px;
}

.address-container {
  margin-bottom: 20px;
}

.socials-container {
  display: flex;
  gap:20px;
  justify-content: center;
}

.socials-container a {
  margin: 0 10px;
  color: white;
}

.App-logo{
  animation: bounce 2s ease-in-out alternate-reverse infinite;
}
.courier-prime-regular {
  max-width: 75%;
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
}
@keyframes bounce {
  0%{
    transform: translateY(0);
  }
  100%{
    transform: translateY(-15px);
  }
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
}
100% {
    background-position: 100% 50%;
}
}

.gradient-text {
  background: linear-gradient(
    to right,
    rgb(3, 7, 247) 20%,
    #5DC62C  30%,
    #5DC62C  70%,
    rgb(3, 7, 247) 80%
);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
text-fill-color: transparent;
background-size: 500% auto;
animation: textShine 1s ease-in-out infinite alternate;
}